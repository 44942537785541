<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">当前位置：<router-link :to="{ name: 'Index' }">首页</router-link>><router-link
            :to="{ name: 'Info' }">个人中心</router-link>>
          <router-link :to="{ name: 'Invoices_status' }">发票管理</router-link>
        </div>
      </div>
      <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status" class="active">发票管理</router-link>
          <!-- modify mao 20250313 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
          <!-- <router-link to="/per_about">关于我们</router-link> -->
        </div>
        <div class="right_box flex-grow-1">
          <div class="flex-box invoice_nav fs18">
            <div class="flex-box flex-grow-1">
              <div class="nav" :class="invoiceIndex == 0 ? 'active' : ''" @click="changeInvoice(0)">
                待开票
              </div>
              <div class="nav" :class="invoiceIndex == 1 ? 'active' : ''" @click="changeInvoice(1)">
                开票中
              </div>
              <div class="nav" :class="invoiceIndex == 2 ? 'active' : ''" @click="changeInvoice(2)">
                已开票
              </div>
            </div>
            <!-- <router-link :to="{ name: 'Invoices_maintain' }" class="maintain normal flex-box">发票信息维护</router-link> -->
          </div>
          <div v-if="cert_list.length == 0 && isShowNavBarLoading == true">
            <div class="no_data">
              <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div v-else>
            <ul class="order_list">
              <li class="mb30" v-for="(item, index) in cert_list" :key="index">
                <div class="flex-box num_date">
                  <div class="col9">订单号：</div>
                  <div>{{ item.tradeNo }}</div>
                  <div class="pl60 col9">{{ item.createTime }}</div>
                </div>
                <div class="flex-box">
                  <div class="goods_info flex-grow-1 flex-box" v-for="(v, index) in item.product">
                    <img :src="oss + v.thumbnail" class="img_about mr20" alt="" />
                    <div class="title">
                      <div class="fs20 fwb">{{ v.productName }}</div>
                      <div class="fs20 num">x{{ item.totalCount_text }}</div>
                    </div>
                  </div>
                  <div class="fifteen fs28 red fwb"><span class="fs16">¥</span>{{  item.price_text }}</div>
                  <div class="fifteen fs20">
                    <div class="pb15 col9">已完成</div>
                    <a href="javascript:;" @click.stop="jump_detail(2, index)">订单详情</a>
                  </div>
                    <div class="fifteen fs20 col9" v-if="item.invoiceStatus > 0 && item.tradeInvoice">{{ item.tradeInvoice[0].materialType == 'ELECTRIC' ? '电子发票' : '纸质发票' }}</div>
                  <div class="fifteen">
                    <div class="btns" v-if="item.invoiceStatus == '0'" @click.stop="add_invoice(index, item.id)">申请发票</div>
                    <div class="btns" v-if="item.invoiceStatus == '1'"  @click.stop="jump_detail(4, index)">开票中</div>
                    <div v-if="item.invoiceStatus > 0 && item.tradeInvoice">
                      <div class="btns"
                        v-if="item.invoiceStatus == '2' && item.tradeInvoice != null && item.tradeInvoice[0].materialType == 'PAPER'"
                        @click.stop="jump_detail(1, index)">发票物流</div>
                        <div class="btns"
                        v-if="item.invoiceStatus == '2' && item.tradeInvoice != null && item.tradeInvoice[0].materialType == 'ELECTRIC'"
                        @click.stop="jump_detail(4, index)">查看pdf发票</div>
                        <div class="btns"
                        v-if="item.invoiceStatus == '2' && item.tradeInvoice != null && item.tradeInvoice[0].materialType == 'ELECTRIC'"
                        @click.stop="jump_detail(5, index)">查看ofd发票</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div> -->
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from "v-viewer"
import cookie from "vue-cookies";
// import hevueImgPreview from 'hevue-img-preview'
export default {
  components: {
    Pagination,
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      oss: '',
      invoiceIndex: 0,
      cert_list: [],
      pageSize:10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: false, // 是否加载完
      invoices_list: [],
    };
  },
  // 页面渲染完执行
  created() {
    let _that = this;
    _that.oss = _that.global.uploadOss
    // 获取抬头
    _that.get_list();
    _that.invoices()
  },
  methods: {

    add_invoice(index, id) {
      let in_list = this.invoices_list
      if (in_list.length <= 0) {
        this.$message.error("请先完善发票信息");
        setTimeout(() => {
          this.$router.push("/invoices_maintain");
        }, 800);
      } else {
        let list = this.cert_list
        var tradeNo = escape('环保桥' + list[index].tradeNo)
        this.$router.push("/invoices_apply?id=" + escape('环保桥' + id) + '&no=' + tradeNo);
      }
    },

    jump_detail(type, index) {
      let that = this;
      let list = that.cert_list
      if (type == 1) {
        //查看物流
        that.$router.push("/invoices_logistics?code=" + escape('环保桥' + list[index].id));
      } else if (type == 3) {
        //查看发票
        let url = list[index].tradeInvoice[0].invoicePic
        if(url){
						let url_arr =  url.split(",")
						 let new_url = []
						  for (let i = 0; i < url_arr.length; i++) {
							   new_url.push(that.oss +url_arr[i]) 
						  }
              that.$hevueImgPreview({
                multiple: true, // 开启多图预览模式
                nowImgIndex: 0, // 多图预览，默认展示第二张图片
                imgList:new_url, // 需要预览的多图数组
            })
					}
      }else if(type == 4 || type == 5){
        let url = list[index].tradeInvoice[0].invoiceFile
        if(url){
          var pdf = ''
          var ofd = ''
						let url_arr =  url.split(",")
						  for (let i = 0; i < url_arr.length; i++) {
                let hz = url_arr[i].substr(url_arr[i].lastIndexOf('.'));
                 if(hz=='.pdf'){
                  pdf = that.oss +url_arr[i] 
                 }else if(hz=='.ofd'){
                  ofd = that.oss +url_arr[i] 
                 }
						  }
              if(type==4){
                window.open(pdf, "_blank");
                that.downFile(pdf)
                return;
              }else{
                window.open(ofd, "_blank");
                that.downFile(ofd)
                return;
              }
					}
      } else if (type == 2){
        that.$router.push("/order_detail?code=" + escape('环保桥' + list[index].id));
      }else{
        var tradeNo = escape('环保桥' + list[index].tradeNo)
        that.$router.push("/invoices_detail?id=" + escape('环保桥' + list[index].id) + '&no=' + tradeNo);
      }
    },

     //直接下载pdf
     downFile(url) {
      const elink = document.createElement("a");
      elink.style.display = "none";
      elink.href = url + '?response-content-type=application/octet-stream';
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },

    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_list();
    },

    get_list(s_param = {}) {
      var that = this;
      let page = that.pageNum;
      s_param.page = page;
      s_param.deleted = '0';
      s_param.limit = that.pageSize;
      s_param.pkg_product_father = 'com.dazz.business.model.product.Product',
        s_param.pkg_tradeInvoice = 'com.dazz.business.model.tradeInvoice.TradeInvoice',
        s_param.status = 'COMPLETE'
      let navIndex = that.invoiceIndex
      if (navIndex == 0) {
        s_param.invoiceStatus = '0'
      } else if (navIndex == 1) {
        s_param.invoiceStatus = '1'
      } else if (navIndex == 2) {
        s_param.invoiceStatus = '2'
      }
      this.$api.getInvoices(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            let list = data_res.root
             for(var i=0;i<list.length;i++){
                list[i].price_text = that.global.addCommas(list[i].product[0].price)
                list[i].totalCount_text = that.global.addCommas(list[i].totalCount)
             }
            that.total = totalCount;
            that.cert_list = list;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading == true
          that.$message.error(data_res.msg);
        }
      });
    },


    invoices() {
      let that = this
      that.$api.userInvoices({}).then((res) => {
        if (res.resCode == 0) {
          that.invoices_list = res.root
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },

    changeInvoice(index) {
      let that = this
      that.invoiceIndex = index;
      that.pageSize = 10, //分页大小
        that.total = 0, //分页总数
        that.pageNum = 1, // 分页记录数
        that.cert_list = []
      that.get_list();
    },



    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },
  },
};
</script>
